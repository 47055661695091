import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'

function NotFound({ location }) {
  return (
    <Layout location={location} title="Jejda! Nic tu není ...">
      <h1>Jejda! Nic tu není ...</h1>
      Nejlepší bude se vrátit na <Link to="/">úvodní stránku</Link>.
    </Layout>
  )
}

export default NotFound
